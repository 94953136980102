
import {
    createRouter,
    createWebHashHistory,
} from 'vue-router'
const routes = [
    {
        path: '/',
        redirect: '/mainPage',
    },
    {
        path: '/mainPage',
        name: 'main',
        component: () => import('@/pages/mainPage'),
    },
    {
        path: '/assistantMain',
        name: 'assistantMain',
        component: () => import('@/pages/assistantMain'),
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
